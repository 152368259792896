import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import TweenMax, {Expo, Linear} from 'gsap/TweenMax';
import CSSPlugin from 'gsap/CSSPlugin'; // eslint-disable-line no-unused-vars
import {
  placeholder, showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow
} from 'utils/animation';
import {getPercentageBetweenBounds} from 'utils';
import scene from 'components/scene'
import {tabbable} from 'utils/accessibility'


import FullScreenContainer from 'components/FullScreenContainer';
import timeline from 'components/timelineComponent';
import CalendarStrip from 'components/CalendarStrip';
import Image from 'components/Image';

import './chapter-6-scene-5.scss';

const duration = 4.25

const C6S5 = class extends React.Component {
  render() {
    const {position, data} = this.props;
    const {background, lbj} = data;

    return (
      <FullScreenContainer className="chapter-6-scene-5">
        <div className="chapter-6-scene-5__background">
          <Image image={background} alt="scene background" sizes="100vw" />
        </div>
        <div className="chapter-6-scene-5__content">
          <div ref="calendar" className="chapter-6-scene-5__content__calendar">
            <CalendarStrip
              position={getPercentageBetweenBounds(this.props.position, (1.5/duration), (2/duration))}
              dates={[
                {
                  number: 7,
                  dateText: 'March to Montgomery',
                  crossedOut: true,
                  lowerText: 'Bloody Sunday',
                },
                {number: 8},
                {
                  number: 9,
                  dateText: 'March to Montgomery',
                  crossedOut: true,
                  lowerText: 'Tuesday Turnaround',
                },
                {number: 10},
                {number: 11},
                {number: 12, targetDate: true},
                {number: 13},
              ]} />
          </div>
          <div className="chapter-6-scene-5__content__lbj">
            <div ref="lbjImage" className="chapter-6-scene-5__content__lbj__image">
              <Image image={lbj} showAria alt="Johnson Calling In his Washington office, US president Lyndon B. Johnson (1908 - 1973) congratulates astronauts Virgil Grissom and John Young after their triple orbit of the Earth, 1965. (Photo by Gene Forte/Central Press/Hulton Archive/Getty Images)" sizes="100vw" />
            </div>
            <div {...tabbable} ref="lbjText" className="chapter-6-scene-5__content__lbj__text">
              <p>On Friday, March 12, LBJ decides he will submit a bill to Congress the following week.</p>
            </div>
          </div>
        </div>
      </FullScreenContainer>
    );
  }
};

const query = graphql`
  query {
      background: file(relativePath: { eq: "chapter-7/scene-1/images/ch7-map-selma_to_montgomery.jpg" }) {
        ...largeImage
      }
      lbj: file(relativePath: { eq: "chapter-6/scene-5/images/ch-6-image-lbj-phone@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 2602) {
            src
            srcSet
          }
        }
      }
  }
`;

const animation = ({context, timeline, easeOut}) => {
  const {calendar, lbjImage, lbjText} = context.refs;

  const verticalShift = window.innerHeight / 4;
  TweenMax.set(lbjImage, {...hideElementWithAria, y: verticalShift});
  TweenMax.set([lbjText,calendar], {...hideFromBelow});

  timeline.to(calendar, 1.5, {...revealFromBelow, ...easeOut})
  timeline.markers.createTimelineMarker()
  // console.log(timeline.duration())
  placeholder(timeline, 0.5)
  // console.log(timeline.duration())

  timeline.to(calendar, 1, {y: -verticalShift, ease: Expo.easeInOut});
  timeline.to(lbjImage, 1.5, {...revealFromBelow, ...easeOut}, '-=0.5');
  timeline.to(lbjText, 1.5, {...revealFromBelow, ...easeOut}, '-=1.25');
  // console.log(timeline.duration())

};


const Component = scene(timeline(C6S5, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
