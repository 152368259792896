import React from 'react';
import FullScreenVideoPlayer from 'components/FullScreenVideoPlayer';
import scene from 'components/scene';
import video from './videos/clip_1.mp4';

const C6S10 = class extends React.Component {
  render() {
    return (
      <FullScreenVideoPlayer src={video} muted autoplay loop />
    );
  }
};


export default scene(C6S10, {duration: 3, stopAtEnd: false});
