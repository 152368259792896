import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {TweenMax} from 'gsap';
import {getPercentageBetweenBounds} from 'utils';
import {
  placeholder, showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow
} from 'utils/animation';
import Image from 'components/Image';
import FullScreenContainer from 'components/FullScreenContainer';
import ClipAndZoomMap from 'components/ClipAndZoomMap';
import timeline from 'components/timelineComponent';
import scene from 'components/scene'
import {tabbable} from 'utils/accessibility'

import './chapter-6-scene-1.scss';

const C6S1 = class extends React.Component {
  render() {
    const {data, position} = this.props;
    const {backgroundImage, californiaImage, massImage, dcImage} = data;

    const animationLength = 20.5
    const firstMapStart = (2.5 / animationLength);
    const firstMapEnd = (10 / animationLength);
    const secondMapStart = (9.5 / animationLength);
    const secondMapEnd = 15 / animationLength;
    const thirdMapStart = (15 / animationLength);
    const thirdMapEnd = 20.5 / animationLength
    const map1Position = getPercentageBetweenBounds(position,firstMapStart, firstMapEnd);
    const map2Position = getPercentageBetweenBounds(position, secondMapStart, secondMapEnd);
    const map3Position = getPercentageBetweenBounds(position, thirdMapStart, thirdMapEnd);

    return (
      <FullScreenContainer className="chapter-6-scene-1">
        <div ref="section1" className="chapter-6-scene-1__section">
          <div className="chapter-6-scene-1__background">
            <Image image={backgroundImage} alt="selma map background" sizes="100vw" />
          </div>
          <div className="chapter-6-scene-1__text">
            <h2 {...tabbable} ref="text1" className="uppercase">Events in <span className="highlight">Selma</span> pressure<br/>LBJ to act</h2>
          </div>
        </div>
        <div ref="section2" className="chapter-6-scene-1__section">
          <div ref="text2" className="chapter-6-scene-1__text">
            <p {...tabbable}>In the week after Bloody Sunday,<br/>
there are student sit-ins and protests across the country:</p>
          </div>
          <div ref="map1" className="chapter-6-scene-1__map">
            <ClipAndZoomMap position={map1Position} state="california" title="San Francisco, California" image={californiaImage.childImageSharp.fluid.src} />
          </div>
          <div ref="map2" className="chapter-6-scene-1__map">
            <ClipAndZoomMap position={map2Position} state="massachusetts" title="Boston, Massachusetts" startOnMap zoomAdjust={0.5} image={massImage.childImageSharp.fluid.src} />
          </div>
          <div ref="map3" className="chapter-6-scene-1__map">
            <ClipAndZoomMap position={map3Position} state="washington-dc" title="Washington, D.C." startOnMap zoomAdjust={0.5} image={dcImage.childImageSharp.fluid.src} />
        </div>
        </div>
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline, easeOut, easeIn}) => {
  const {section1, section2, text1, text2, map1, map2, map3} = context.refs;
  TweenMax.set(section1, {...showElemWithAria});
  TweenMax.set([section2, map2, map3], {...hideElementWithAria, scale: 1});
  TweenMax.set([text1, text2], {...hideFromBelow});

  timeline.to(text1, 1.5, {...revealFromBelow, ...easeOut});

  timeline.markers.createTimelineMarker()

  timeline.to(section1, 1, {...hideElementWithAria});
  timeline.to(section2, 1, {...showElemWithAria}, '-=0.5');
  // console.log('map1', timeline.duration())

  timeline.to(text2, 1.5, {...revealFromBelow, ...easeOut}, "-=0.5");
  placeholder(timeline, 0.5); // finish map build-out

  timeline.markers.createTimelineMarker()
  timeline.to(text2, 0.5, {...hideElementWithAria});
  placeholder(timeline, 4.5); // zoom animation
  // console.log('map1', timeline.duration())

  timeline.to(map1, 1, {...hideElementWithAria}, "-=0.5");
  timeline.to(map2, 1, {...showElemWithAria}, "-=1")
  // console.log('map2', timeline.duration())
  placeholder(timeline, 5); // zoom animation
  // console.log('map2', timeline.duration())
  timeline.to(map2, 1, {...hideElementWithAria}, "-=0.5");
  timeline.to(map3, 1, {...showElemWithAria}, "-=1")
  // console.log('map3', timeline.duration())
  placeholder(timeline, 5); // zoom animation
  // console.log('map3', timeline.duration())

};

const query = graphql`
  query {
      backgroundImage: file(relativePath: { eq: "chapter-6/scene-1/images/ch-6-image-map-river-bg@2x.jpg" }) {
        ...largeImage
      }
      californiaImage: file(relativePath: { eq: "chapter-6/scene-1/images/ch-6-image-berkeley-protest-sepia@2x.jpg" }) {
        ...largeImage
      }
      massImage: file(relativePath: { eq: "chapter-6/scene-1/images/ch-6-image-boston-sit-in-sepia@2x.jpg" }) {
        ...largeImage
      }
      dcImage: file(relativePath: { eq: "chapter-6/scene-1/images/ch-6-image-whitehouse-protest-sepa@2x.jpg" }) {
        ...largeImage
      }
  }
`;

const Component = scene(timeline(C6S1, {animation}),{stopAtEnd: false});
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
