import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import TweenMax, {Expo, Linear} from 'gsap/TweenMax';
import {
  placeholder, showElemWithAria, hideElementWithAria,hideFromBelow, revealFromBelow
} from 'utils/animation';
import scene from 'components/scene'

import SplitView, {viewTypes} from 'templates/SplitView';
import FullScreenContainer from 'components/FullScreenContainer';
import FullScreenImage from 'components/FullScreenImage';
import timeline from 'components/timelineComponent';
import TextMask from 'components/TextMask';
import {stickyPositions} from 'components/ModalContent';
import {getPercentageBetweenBounds} from 'utils';
import Image from 'components/Image';
import {tabbable} from 'utils/accessibility'

import './chapter-6-scene-8.scss';

const C6S8 = class extends React.Component {
  render() {
    const {position, data} = this.props;
    const {background, lbjSepia, lbjRed} = data;

    const textMaskProgress = getPercentageBetweenBounds(position, 0.7, 1);

    return (
      <FullScreenContainer className="chapter-6-scene-8">
        <div ref="background">
          <FullScreenImage
            image={background}
            showAria
            alt="President Lyndon B. Johnson addresses members of Congress."
            aspectRatio={8/5}
            className="chapter-6-scene-8__background">
            <div className="chapter-6-scene-8__background__overlay">
              <svg viewBox="0 0 100 62.5" preserveAspectRatio="none">
                <defs>
                  <radialGradient id="c6s8MaskGradient">
                    <stop offset="10%" stopColor="black" />
                    <stop ref="stop" offset="95%" stopColor="white" />
                  </radialGradient>

                  <mask id="c6s8Mask">
                    <rect width="100%" height="100%" fill="white" />
                    <circle ref="circleMask" cx="35%" cy="50%" r="25%" fill="url('#c6s8MaskGradient')" />
                  </mask>
                </defs>
                <rect width="100%" height="100%" fill="black" mask="url(#c6s8Mask)" />
              </svg>
            </div>
          </FullScreenImage>
        </div>

        <div className="chapter-6-scene-8__texts">
          <div className="chapter-6-scene-8__texts__text">
            <p {...tabbable} ref="text1" className="intro">
              On March 15, 1965,<br/>LBJ delivers an address on<br/>voting rights to Congress.
            </p>
          </div>
          <div className="chapter-6-scene-8__texts__text">
            <p {...tabbable} ref="text2" className="subheading">He condemns the violence at Selma and urges<br/>swift passage of a new voting rights law to end<br/>poll taxes, literacy requirements, and other<br/>impediments to the vote. </p>
          </div>
          <div className="chapter-6-scene-8__texts__text">
            <p {...tabbable} ref="text3" className="subheading">Famously, he uses the iconic words of the civil rights movement's most precious anthem, declaring:</p>
          </div>
        </div>

        <div ref="lbj" className="chapter-6-scene-8__lbj">
          <TextMask
            className="mask"
            backgroundImage={lbjSepia}
            maskImage={lbjRed.childImageSharp.fluid.src}
            text="“And We Shall Overcome.”"
            position={textMaskProgress}
            showAria
            alt="U.S. President Lyndon B. Johnson addresses a joint session of Congress in Washington, D.C., March 15, 1965, to outline his proposals for voting rights for all citizens. Vice President Hubert Humphrey is at left and House Speaker John McCormack is at right. (AP Photo)"
          />
        </div>
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline, easeOut, easeIn}) => {

  const {background, stop, circleMask, text1, text2, text3, lbj} = context.refs;

  TweenMax.set(background, {y: window.innerHeight / 4, scale: 1.5});
  TweenMax.set(stop, {attr: {'stop-color': 'white'}});
  TweenMax.set(circleMask, {attr: {r: '25%'}});

  TweenMax.set([text1,text2,text3], {...hideFromBelow});
  TweenMax.set([lbj], {...hideElementWithAria})
  TweenMax.set(lbj, {y: '100%'});

  timeline.to(text1, 1.5, {...revealFromBelow, ...easeOut})

  timeline.markers.createTimelineMarker()

  timeline.to(text1, 1.5, {...hideElementWithAria, y: -50, ...easeOut});
  timeline.to(background, 1.5, {y: 0, scale: 1, ease: Expo.easeInOut}, "-=1.5");
  timeline.to(circleMask, 0.75, {attr: {r: '100%'}, ...easeIn}, '-=1.5');
  timeline.to(stop, 0.5, {attr: {'stop-color': 'black'}}, '-=0.75');

  timeline.to(text2, 1.5, {...revealFromBelow, ...easeOut, onStart: () => {
    requestAnimationFrame(() => text2.focus())
  }}, "-=0.5");

  timeline.markers.createTimelineMarker()

  timeline.to(text2, 1.5, {...hideElementWithAria, y: -50, ...easeOut});
  timeline.to(text3, 1.5, {...revealFromBelow, ...easeOut, onStart: () => {
    requestAnimationFrame(() => text3.focus())
  }}, "-=0.5");

  timeline.markers.createTimelineMarker()

  timeline.to(text3, 1.5, {...hideElementWithAria, y: -50, ...easeOut});

  timeline.to(lbj, 2, {y: '0%', ...easeOut, ...showElemWithAria});

  timeline.markers.createTimelineMarker()
  placeholder(timeline, 2);

};

const query = graphql`
  query {
      background: file(relativePath: { eq: "chapter-6/scene-8/images/ch6-image-lbj_congress.jpg" }) {
        ...largeImage
      }
      lbjSepia: file(relativePath: { eq: "chapter-6/scene-8/images/ch-6-image-lbj-speech-closeup-sepia@2x.png" }) {
        ...largeImage
      }
      lbjRed: file(relativePath: { eq: "chapter-6/scene-8/images/ch-6-image-lbj-speech-closeup-red@2x.png" }) {
        ...largeImage
      }
  }
`;

const Component = scene(timeline(C6S8, {animation}), {stopAtEnd: false});
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
