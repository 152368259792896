import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import TweenMax, {Expo, Linear} from 'gsap/TweenMax';
import {placeholder} from 'utils/animation';
import FullScreenContainer from 'components/FullScreenContainer';
import timeline from 'components/timelineComponent';
import Image from 'components/Image';
import scene from 'components/scene'
import {tabbable} from 'utils/accessibility'


import './chapter-6-scene-11.scss';

const C6S11 = class extends React.Component {
  render() {
    const {image} = this.props.data;
    return (
      <FullScreenContainer className="chapter-6-scene-11">
        <div {...tabbable} className="chapter-6-scene-11__image" ref="image">
          <Image image={image} alt="MLK Watches LBJ American religious and Civil Rights leader Martin Luther King Jr (1929 - 1968) watches US President Lyndon Johnson on television, Selma, Alabama, March 1965. (Photo by Frank Dandridge/The LIFE Images Collection/Getty Images)" sizes="100vw" showAria />
        </div>
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline, easeOut}) => {

  const {image} = context.refs;

  TweenMax.set(image, {scale: 2, x: '-50%', y: '-50%'});
  placeholder(timeline,1);
  timeline.to(image, 2, {scale: 1, x: '0%', y: '0%', ease: Expo.easeInOut});


};

const query = graphql`
  query {
      image: file(relativePath: { eq: "chapter-6/scene-11/images/ch-6-image-lbj-speech-on-tv-wide@2x.jpg" }) {
        ...largeImage
      }
  }
`;

const Component = scene(timeline(C6S11, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
