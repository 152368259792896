export const Chapter6Qa = [
  {
    questionNumber: '6.01',
    question: 'President Johnson issues a statement in support of voting rights in February 1965; why did it take until August for the VRA to be signed into law?',
    answer: `The bill had to go before the U.S. Senate and the House of Representatives. The Senate passed the bill on May 26, 1965. After a month long debate the House passed the bill on July 9, 1965.`,
  },
  {
    questionNumber: '6.02',
    question: 'Why was President Johnson’s use of the phrase "we shall overcome" meaningful to marchers?',
    answer: `It was a phrase used by African Americans in the civil rights movement. SCLC leaders and many of the  marchers felt Johnson was saying he was one of them. He was on their side.`,
  },
  {
    questionNumber: '6.03',
    question: 'Why was the federal government unable to protect the right to vote before the passage of the Voting Rights Act?',
    answer: `The Department of Justice had to bring individual court cases to prove that state or county was discriminating. The burden of proof was on the federal government.`,
  },
  {
    questionNumber: '6.04',
    question: 'How did federal and local elected officials help or hinder the passage of the Voting Rights Act of 1965?',
    answer: `Answers will vary and may include: Sheriff Jim Clark used his power to intimidate protesters. Governor Wallace used his power to enable the AL state troopers’ use of force. LBJ used his power to meet with leaders of the movement and mediate between the movement and Alabama officials`,
  },
];
