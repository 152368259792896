import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import TweenMax, { Expo, Linear } from 'gsap/TweenMax';
import { getPercentageBetweenBounds } from 'utils';
import {
  showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow
} from 'utils/animation';
import { TimelineMarkers } from 'utils/scene';
import SplitView, { viewTypes } from 'templates/SplitView';
import FullScreenContainer from 'components/FullScreenContainer';
import ScrollContainer from 'components/ScrollContainer';
import FullScreenImage from 'components/FullScreenImage';
import timeline from 'components/timelineComponent';
import Image from 'components/Image';
import HorizontalShift from 'components/HorizontalShift';
import Modal from 'components/ChapterModal';
import scene from 'components/scene';
import { stickyPositions } from 'components/ModalContent';
import {tabbable} from 'utils/accessibility'

import './chapter-6-scene-4.scss';

const C6S4 = class extends React.Component {
  render() {
    const { data } = this.props;
    const {
      background,
      capitalIllustration,
      meetingBg,
      meetingFg,
      quote,
      doodle
    } = data;
    return (
      <FullScreenContainer className="chapter-6-scene-4">
        <div className="chapter-6-scene-4__section">
          <SplitView
            // viewType={viewTypes.TWO_THIRDS}
            fullWidthPanel={
              <div ref="section1Image">
                <Image
                  className="chapter-6-scene-4__image"
                  image={background}
                  alt="Twelve protestors stage a sit-in demonstration at the White House in relation to Civil Rights."
                  sizes="100vw"
                  showAria
                />
              </div>
            }
            rightPanel={
              <div {...tabbable} ref="section1Text" className="chapter-6-scene-4__text">
                <p className="alt">
                  There is even a sit-in very close to the president’s residence
                  when 12 protesters join a White House tour.
                </p>
                <p>
                  The student protesters demand{' '}
                  <span className="highlight">
                    immediate voting rights legislation
                  </span>
                  .
                </p>
              </div>
            }
          />
        </div>
        <div
          ref="section2"
          className="chapter-6-scene-4__section chapter-6-scene-4__meeting">
          <div>
            <FullScreenImage
              aspectRatio={8 / 5}
              image={meetingBg}
              alt="white house meeting background">
              <Image
                className="chapter-6-scene-4__meeting__foreground"
                image={meetingFg}
                alt="President Lyndon B. Johnson meets with Civil Rights group and church leaders."
                sizes="100vw"
                showAria
              />
              <div className="chapter-6-scene-4__meeting__doodle">
                <Image
                  image={doodle}
                  alt="circle around LBJ"
                  sizes="168px"
                />
                <div className="chapter-6-scene-4__meeting__name">
                  <p className="name">LBJ</p>
                </div>
              </div>

            </FullScreenImage>
          </div>
        </div>
        <FullScreenContainer>
          <ScrollContainer alignLeft className="chapter-6-scene-4__meeting__container">
            <div className="chapter-6-scene-4__meeting__text">
              <p {...tabbable} ref="section2Text1" className="alt">
                That week, LBJ meets with angry members of Congress, delegations
                from SNCC, SCLC, other civil rights groups, and a group of church
                leaders.
              </p>
              <p {...tabbable} ref="section2Text2">
                All press him to act on Selma by sending a voting rights bill to
                Congress, <span className="highlight">but LBJ hesitates</span>.{' '}
              </p>
              <div ref="section2Modal" className="chapter-6-scene-4__modal-container">
                <Modal
                  className="chapter-6-scene-4__modal"
                  stickyPosition={stickyPositions.NO_STICKY}>
                  <div className="chapter-6-scene-4__modal__content">
                    <div className="chapter-6-scene-4__modal__content__image">
                      <Image
                        image={capitalIllustration}
                        width={243}
                        alt="capital building"
                        sizes="243px"
                      />
                    </div>
                    <div className="chapter-6-scene-4__modal__content__text">
                      <p className="alt">
                        In July 1964 LBJ orders Acting Attorney General Nicholas
                        Katzenbach:
                      </p>
                      <div className="quote" ref="quote">
                        <Image image={quote} />
                      </div>
                      <p className="alt order">
                        I want you to write me the goddamndest, toughest voting
                        rights act that you can devise."
                      </p>
                      <p className="alt">
                        The bill is nearly ready, but LBJ is unsure that he can get
                        it through Congress.
                      </p>
                      <p className="alt">
                        He fears the bill might be filibustered by Southern
                        Democrats or opposed by Republicans.
                        <br />
                        <br />
                        He worries that the bill will be declared unconstitutional
                        by the Supreme Court.
                      </p>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </ScrollContainer>
        </FullScreenContainer>

      </FullScreenContainer>
    );
  }
};

const query = graphql`
  query {
    background: file(
      relativePath: {
        eq: "chapter-6/scene-4/images/ch-6-image-whitehouse-sit-in-sepia@2x.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 2616) {
          ...selmaFluidImage
        }
      }
    }
    capitalIllustration: file(
      relativePath: {
        eq: "chapter-6/scene-4/images/ch-6-illustration-capital-question@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 484) {
          ...selmaFluidImage
        }
      }
    }
    meetingBg: file(
      relativePath: {
        eq: "chapter-6/scene-4/images/ch-6-image-lbj-meeting-black-leaders-background@2x.jpg"
      }
    ) {
      ...largeImage
    }
    meetingFg: file(
      relativePath: {
        eq: "chapter-6/scene-4/images/ch-6-image-lbj-meeting-black-leaders-foreground@2x.png"
      }
    ) {
      ...largeImage
    }
    quote: file(relativePath: { eq: "chapter-2/scene-9/images/quote@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 72) {
          ...selmaFluidImage
        }
      }
    }
    doodle: file(
      relativePath: {
        eq: "chapter-6/scene-4/images/ch6-doodle-lbj_circle_arrow.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 238) {
          ...selmaFluidImage
        }
      }
    }
  }
`;


const animation = ({context, timeline, easeOut, easeIn}) => {
  const {
    section1Image,
    section1Text,
    section2,
    section2Text1,
    section2Text2,
    section2Modal,
  } = context.refs;

  TweenMax.set(section1Image, { ...showElemWithAria });
  TweenMax.set([section1Text, section2Text1, section2Text2], { ...hideFromBelow });
  TweenMax.set([section2,section2Modal], { ...hideElementWithAria });

  timeline.to(section1Text, 1.5, {...revealFromBelow, ...easeOut });
  timeline.markers.createTimelineMarker();
  // timeline.to(section1Text, 1, { ...hideElementWithAria, y: -50, ...easeOut });

  timeline.to([section1Text, section1Image], 1, { ...hideElementWithAria });
  timeline.to(section2, 1, { ...showElemWithAria }, "-=0.5");
  timeline.to(section2Text1, 1.5, { ...revealFromBelow, ...easeOut }, "-=0.5");
  timeline.to(section2Text2, 1.5, { ...revealFromBelow, ...easeOut }, "-=1.25");
  timeline.to(section2Modal, 1, { ...showElemWithAria}, "-=1.25");

  timeline.markers.createTimelineMarker();
};

const Component = scene(timeline(C6S4, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
