import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import TweenMax, {Expo, Linear} from 'gsap/TweenMax';
import {
  placeholder, showElemWithAria, hideElementWithAria,hideFromBelow, revealFromBelow
} from 'utils/animation';
import scene from 'components/scene'
import {tabbable} from 'utils/accessibility'

import SplitView, {viewTypes} from 'templates/SplitView';
import FullScreenContainer from 'components/FullScreenContainer';
import timeline from 'components/timelineComponent';
import {stickyPositions} from 'components/ModalContent';
import Image from 'components/Image';

import './chapter-6-scene-6.scss';

const C6S6 = class extends React.Component {
  render() {
    const {wallaceLbj} = this.props.data;
    return (
      <FullScreenContainer className="chapter-6-scene-6">
        <SplitView
          leftPanel={
            <div {...tabbable} ref="text" className="chapter-6-scene-6__text">
              <p>While there are differing accounts of the conversation, LBJ meets with Governor
George Wallace of Alabama at the White House
on March 13.</p>
              <p className="alt">If he can persuade Wallace to support the bill, perhaps its passage will be easier.</p>
              <p className="alt">Both men want to avoid a repeat of Bloody Sunday’s violence, but Wallace will not support the president on
 voting rights.</p>
            </div>
          }
          rightPanel={
            <Image className="chapter-6-scene-6__image" showAria image={wallaceLbj} alt="Wallace And Johnson 13th March 1965: Alabama Governor George Wallace and US President Lyndon B. Johnson are surrounded by reporters as they leave their 3 hour meeting, Washington DC. (Photo by Gene Forte/Consolidated News Pictures/Getty Images)" sizes="50vw" />
          } />
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline, easeOut}) => {
  const {text} = context.refs;

  TweenMax.set(text, {...hideFromBelow});
  timeline.to(text, 1.5, {...revealFromBelow, ...easeOut});

};

const query = graphql`
  query {
      wallaceLbj: file(relativePath: { eq: "chapter-6/scene-6/images/ch-6-image-lbj-wallace@3x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2160) {
            ...selmaFluidImage
          }
        }
      }
  }
`;

const Component = scene(timeline(C6S6, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
