import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import MovieScene from 'components/MovieScene';
import scene from 'components/scene';
import Clip from './ch6-SCENE-10-LBJ_Wallace_And_The_Nation.mp4';

const Chapter6Scene7 = ({data}) => (
  <div>
    <MovieScene
      sceneNumber={10}
      sceneSubtitle={
        <span><span className="italic">SELMA</span> SCENE 10</span>
      }
      sceneDescription="LBJ, Wallace, and the nation"
      firstImage={data.firstImage}
      lastImage={data.firstImage}
      videoSrc={Clip}
    />

  </div>
);

const query = graphql`
  query {
      firstImage: file(relativePath: { eq: "chapter-6/scene-7/images/ch-6-image-lbj-wallace-clip-still@3x.jpg" }) {
        ...largeImage
      }
  }
`;

const SceneComponent = scene(Chapter6Scene7);
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
