import React from 'react';
import {graphql} from 'gatsby';

import ChapterPage from 'components/chapter';
import ChapterIntro from 'templates/ChapterIntro';
import ChapterConclusion from 'components/ChapterConclusion';

import {AppSettings} from 'components/Layout';

import Chapter6Scene1 from 'components/scenes/chapter-6/scene-1/Chapter6Scene1';
import Chapter6Scene4 from 'components/scenes/chapter-6/scene-4/Chapter6Scene4';
import Chapter6Scene5 from 'components/scenes/chapter-6/scene-5/Chapter6Scene5';
import Chapter6Scene6 from 'components/scenes/chapter-6/scene-6/Chapter6Scene6';
import Chapter6Scene7 from 'components/scenes/chapter-6/scene-7/Chapter6Scene7';
import Chapter6Scene8 from 'components/scenes/chapter-6/scene-8/Chapter6Scene8';
import Chapter6Scene9 from 'components/scenes/chapter-6/scene-9/Chapter6Scene9';
import Chapter6Scene10 from 'components/scenes/chapter-6/scene-10/Chapter6Scene10';
import Chapter6Scene11 from 'components/scenes/chapter-6/scene-11/Chapter6Scene11';
import {Chapter6Qa} from 'components/scenes/chapter-6/scene-11/Chapter6Qa';

class Chapter6 extends React.Component {
  static contextType = AppSettings

  render() {
    const {data} = this.props;
    const {heroImage, endImage} = data;

    const title = 'Presidential Support'

    return (
      <div className="chapter-6">
        <ChapterPage
          chapterNumber={6}
          title={title}
          scenes={[
            <ChapterIntro
              title="Chapter 6"
              subtitle={title}
              hero={heroImage}
              alt="President Lyndon B. Johnson speaking before signing the Voting Rights Act"
            />,
            <Chapter6Scene1 />,
            <Chapter6Scene4 />,
            <Chapter6Scene5 />,
            <Chapter6Scene6 />,
            <Chapter6Scene7 />,
            <Chapter6Scene8 />,
            <Chapter6Scene9 />,
            <Chapter6Scene10 />,
            <Chapter6Scene11 />,
            <ChapterConclusion
              image={endImage}
              questions={Chapter6Qa}
              chapterNumber={6}
              nextChapterTitle="The Right to Vote"
              theme="dark"
            />
          ]} />
      </div>
    );
  }
}

export const query = graphql`
  query {
      heroImage: file(relativePath: { eq: "chapter-6/ch-6-image-lbj-press-conference@2x.png" }) {
        ...largeImage
      }
      endImage: file(relativePath: { eq: "chapter-6/ch-6-image-group-protest-righttovote@2x.jpg" }) {
        ...largeImage
      }
  }
`;

export default Chapter6
