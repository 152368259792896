import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Image from 'components/Image';

import MovieScene from 'components/MovieScene';
import scene from 'components/scene';
import Clip from './ch6-SCENE-11-Power_Of_The_Presidency.mp4';

const Chapter6Scene9 = ({data}) =>(
  <div>
    <MovieScene
      sceneNumber={11}
      sceneSubtitle={
        <span><span className="italic">SELMA</span> SCENE 11</span>
      }
      sceneDescription="POWER OF THE PRESIDENCY"
      firstImage={data.firstImage}
      lastImage={data.firstImage}
      videoSrc={Clip}
    />

  </div>
);

const query = graphql`
  query {
      firstImage: file(relativePath: { eq: "chapter-6/scene-9/images/ch-6-image-power-of-presidency-play@2x.jpg" }) {
        ...largeImage
      }
  }
`;

const SceneComponent = scene(Chapter6Scene9);
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
